import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import {
  s,
  colors,
  sContainer,
  sTitle,
  sImage,
  sGatsbyImage,
  sHtmlList,
  sButtons,
  sContainerPadding,
} from '../style'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Seo from '../components/Seo'
import Button from '../components/Button'
import alt from '../content/alt'

export default function JobTemplate({ data }) {
  const { city, title, slug, image, body, description, link } = data.job

  return (
    <Layout white>
      <Seo
        title={title}
        desc={description}
        imageUrl={image.file.url}
        url={'kariera/' + slug}
        noIndex
      />
      <section css={[sContainer, sContainerPadding]}>
        <h1 css={sTitle}>{title}</h1>
        <h2 css={sCity}>{city}</h2>
        <div css={[sImage, { backgroundColor: colors.greyLighter }]}>
          <GatsbyImage
            image={getImage(image)}
            style={sGatsbyImage}
            alt={city + alt}
          />
        </div>
        <article
          css={[sHtmlList, { em: { fontSize: '0.75rem', opacity: 0.6 } }]}
          dangerouslySetInnerHTML={{
            __html: body.childMarkdownRemark.html.concat(`
            <em>
            Zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych
            osobowych z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z
            04.05.2016) informujemy, iż administratorem Pani/Pana danych
            osobowych jest Omida Logistics Sp. z o.o. al. Grunwaldzka 472,
            80-309 Gdańsk. Pani/Pana dane osobowe przetwarzane będą dla potrzeb
            aktualnej i przyszłych rekrutacji na podstawie: art. 6 ust. 1 lit. a
            - zgody osoby, której dane dotyczą, art. 6 ust.1 lit. c - przepis
            prawa - Kodeks Pracy z dnia 26 czerwca 1974 r. - ogólnego
            rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016
            r. (RODO). Pani/Pana dane osobowe przechowywane będą przez okres 30
            dni od zakończenia aktualnej rekrutacji. Odbiorcami Pani/Pana danych
            osobowych będą wyłącznie podmioty uprawnione do uzyskania danych
            osobowych na podstawie przepisów prawa a także podmioty
            przetwarzające. Posiada Pani/Pan prawo do żądania od administratora
            dostępu do danych osobowych, prawo do ich sprostowania, usunięcia
            lub ograniczenia przetwarzania, prawo do przenoszenia danych, prawo
            do cofnięcia zgody w dowolnym momencie. Ma Pani/Pan prawo wniesienia
            skargi do organu nadzorczego. Podanie danych osobowych jest
            obligatoryjne w oparciu o przepisy prawa a w pozostałym zakresie
            jest dobrowolne, lecz niepodanie danych może skutkować odmową
            uczestnictwa w procesie rekrutacji. Każda CV powinna mieć poniższą
            klauzurę: ’Zgodnie z art.6 ust.1 lit. a ogólnego rozporządzenia o
            ochronie danych osobowych z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L
            119 z 04.05.2016) wyrażam zgodę na przetwarzanie moich danych
            osobowych dla potrzeb aktualnej i przyszłych rekrutacji.
            </em>
            `),
          }}></article>
        <div css={sButtons}>
          <Button glow link={link} extraCss={{ [s.md]: { width: '60%' } }}>
            Aplikuj
          </Button>
          <Button reactive link={'/oferty-pracy/'}>
            Pozostałe Oferty
          </Button>
        </div>
      </section>
    </Layout>
  )
}

const sCity = {
  fontSize: '1.25rem',
  textTransform: 'uppercase',
  opacity: 0.3,
  // letterSpacing: '0.25em',
  marginBottom: '2rem',
  transform: 'translateY(-50%)',
}

export const query = graphql`
  query ($id: String!) {
    job: contentfulPraca(id: { eq: $id }) {
      id
      createdAt
      updatedAt
      slug
      city
      link
      title
      description
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        gatsbyImageData(
          quality: 100
          placeholder: DOMINANT_COLOR
          formats: [JPG]
        )
        file {
          url
          fileName
        }
      }
    }
  }
`
